<template>
    <div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <EpisodeHeader :episodes="episodes" :episode="computedEpisode" :tab="'ACTION_PLAN'"
                @episode-changed="episodeChanged" />
        </div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <RecordTypeTable :tableData="computedRecordTypes" :episode="computedEpisode" />
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import GET_RECORD_COUNTS_FOR_EPISODE from '@/graphql/queries/getRecordCountsForEpisode.gql'
import { mapState } from 'vuex'
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import RecordTypeTable from '@/components/records/partials/RecordTypeTable'

import RECORD_TYPES from '@/json/recordTypes.json'

export default {
    name: 'Records',
    components: { RecordTypeTable, EpisodeHeader},
    apollo: {
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ episodes }) => {
                const openEpisodes = episodes.filter(e => e && e.status === 'OPEN')
                const otherEpisodes = episodes.filter(e => e && e.status !== 'OPEN')
                return camelcaseKeys([...openEpisodes, ...otherEpisodes], { deep: true })
            },
        },
        recordCounts: {
            query: GET_RECORD_COUNTS_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.currentEpisode.episodeId),
                }
            },
            skip() {
                return !this.currentEpisode?.episodeId
            },
            update: ({ alertCountForEpisode, documentCountForEpisode, phoneLogCountForEpisode, telephoneContactCountForEpisode, analysisResultCountForEpisode, medicationCountForEpisode }) => {
                return {
                    ALERT: alertCountForEpisode.aggregate.count,
                    DOCUMENT: documentCountForEpisode.aggregate.count,
                    CONTACT_LOG: phoneLogCountForEpisode.aggregate.count + telephoneContactCountForEpisode.aggregate.count,
                    ANALYSIS_RESULT: analysisResultCountForEpisode.aggregate.count,
                    MEDICATIONS: medicationCountForEpisode.aggregate.count
                }
            },
        },
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            episodes: [],
            episode: {},
            filterRecordTypes: ['ANALYSIS_RESULT', 'MEDICATIONS']
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
        this.$apollo.queries.recordCounts.refetch()
    },
    computed: {
        ...mapState({
            currentEpisode: state => state.app.currentEpisode
        }),
        computedEpisode() {
            return this.currentEpisode || this.episode
        },
        computedRecordTypes() {
            let recordTypes 
            const curEpisode = this.currentEpisode

            if (curEpisode && curEpisode.type !== 'RESIDENTIAL') {
                recordTypes = RECORD_TYPES.filter(rt => !this.filterRecordTypes.includes(rt.type))
            } else {
                recordTypes = RECORD_TYPES
            }
            recordTypes = recordTypes.map(recordType => {
                return { ...recordType, count: (this.recordCounts && this.recordCounts[recordType.type]) ?? 0 }
            })
            return recordTypes
        },
    },
    methods: {
        episodeChanged(episode) {
            this.$store.commit('SET_CURRENT_EPISODE', episode)
            this.episode = { ...episode }
        }
    },
    watch: {
        '$route.path': function (val, oldVal) {
            this.$apollo.queries.client.refetch()
        }
    }
}
</script>
