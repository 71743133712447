<template>
    <div class="c-action-plan__table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            hide-default-footer
            item-key="type"
            :headers="headers"
            :items="tableData"
            class="a-table w-full"
            data-cy="recordList"
            @click:row="rowClickHandler"
        >
            <template class="" v-slot:item.name="{ item }">
                <div class="flex items-center py-2">
                    <TypeIcon
                        class="w-12 mr-4 inline-block text-primary-light hideSmallTablet"
                        :rowData="item"
                        svgColour="text-primary-light"
                    />
                    {{item.name}}
                </div>
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }">
                <div class="text-right" :class="{ 'active-tr': item.active}">
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import TypeIcon from '@/components/partials/TypeIcon'

export default {
    name: 'RecordTypeTable',
    components: { TypeIcon },
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        episode: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            headers: [
                { text: 'Name', align: 'left', value: 'name', class: 'name--header', sortable: false },
                { text: 'Records', align: 'left', value: 'count', class: 'count--header', sortable: false },
                { text: '', align: 'left', value: 'editRecord', fixed: true, sortable: false},
            ]
        }
    },
    methods: {
        rowClickHandler(item) {
            console.log('item:', item)
            // this.$store.commit('SET_CURRENT_EPISODE', item.episode)
            this.$router.push({
                name: 'record-view',
                params: {clientId: this.clientId, type: item.type},
            }).catch(err => err)
        },
    },
}
</script>
